<template>
  <div class="thesaurus-report-type">
    <el-row
      class="mb-20"
      :gutter="20">
      <el-col :span="6">
        <el-input
          v-model="query.f.search.search"
          type="text"
          placeholder="Поиск"
          clearable
          @input="value => _getReportTypeList({f: {search: {search: value}}})" />
      </el-col>
      <el-col
        :span="18"
        align="right">
        <el-button
          class="right"
          type="primary"
          @click="dataForm.visible = true">
          Добавить
        </el-button>
      </el-col>
    </el-row>
    <el-row
      class="mb-20"
      :gutter="20">
      <el-col>
        <el-table
          v-loading="data.loading"
          class="mb-20"
          border
          height="calc(100vh - 189px)"
          :data="data.list">
          <el-table-column
            prop="title"
            label="Наименование"
            align="left" />
          <el-table-column
            prop="id"
            label="Код"
            align="center"
            width="90" />
          <el-table-column
            align="center"
            width="150">
            <template #default="item">
              <el-button
                type="primary"
                circle
                @click="_reportTypeEdit(item.row.id)">
                <el-icon><Edit /></el-icon>
              </el-button>
              <el-popconfirm
                confirm-button-text="Да"
                cancel-button-text="Нет"
                icon="el-icon-info"
                icon-color="red"
                title="Подтвердите чтобы удалить"
                @confirm="_reportTypeDelete(item.row.id)">
                <template #reference>
                  <el-button
                    class="el-icon-delete"
                    type="danger"
                    circle>
                    <el-icon><Delete /></el-icon>
                  </el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-model:currentPage="query.p.page"
          background
          align="left"
          layout="prev, pager, next"
          :page-size="query.p.per_page"
          :total="query.p.total"
          @current-change="value => _getReportTypeList({page: value})" />
      </el-col>
    </el-row>
    <el-dialog
      v-model="dataForm.visible"
      :title="dataForm.edit ? 'Редактировать' : 'Добавить'"
      width="500px"
      :close-on-click-modal="false"
      :top="'12vh'"
      :before-close="_handleClose">
      <el-form
        :ref="dataForm.ref"
        v-loading="dataForm.loading"
        :model="dataForm.fields"
        :rules="dataForm.rules">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item
              label="Наименование"
              prop="title">
              <el-input
                v-model="dataForm.fields.title"
                clearable
                placeholder="Не заполнено" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="_handleClose">Отмена</el-button>
          <el-button
            v-if="dataForm.edit"
            type="primary"
            @click="_reportTypeAdd(dataForm.fields)">Изменить</el-button>
          <el-button
            v-else
            type="primary"
            @click="_reportTypeAdd(dataForm.fields)">Сохранить</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import _merge from 'lodash/merge'
import { replaceObjectValues } from '@/helps'
import { Edit, Delete } from '@element-plus/icons-vue'
import { REPORT } from '@/api'

export default {
  name: 'ReportType',
  components: {
    Edit,
    Delete
  },
  data () {
    return {
      loading: false,
      data: {
        loading: false,
        list: []
      },
      dataForm: {
        loading: false,
        visible: false,
        edit: false,
        ref: 'dataForm',
        fields: {
          id: undefined,
          title: undefined
        },
        rules: {
          title: {
            required: true,
            message: 'Не заполнено'
          }
        }
      },
      query: {
        f: {
          search: {
            search: undefined
          }
        },
        p: {
          count: 0,
          page: 1,
          per_page: 20,
          total: 0,
          total_pages: 0
        }
      }
    }
  },
  created () {
    this._getReportTypeList()
  },
  methods: {
    _getReportTypeList  (data = {}) {
      this.data.loading = true
      this.query = _merge(this.query, data)

      this.$http.get(REPORT.TYPE_LIST, { params: this.query }).then(response => {
        this.data.list = response.data.list
        this.query = _merge(this.query, { p: response.params.p })
        this.$router.replace({ query: this.query })
      }).finally(() => {
        this.data.loading = false
      })
    },

    _handleClose () {
      this.dataForm.edit = false
      this.dataForm.visible = false
      this.$refs[this.dataForm.ref].resetFields()
    },

    _reportTypeAdd (data = {}) {
      this.$refs[this.dataForm.ref].validate((valid) => {
        if (valid) {
          this.dataForm.loading = true
          const method = this.dataForm.edit ? REPORT.TYPE_EDIT : REPORT.TYPE_ADD
          this.$http.post(method, data).then(() => {}).finally(() => {
            this.dataForm.loading = false
            this._handleClose()
            this._getReportTypeList()
          })
        }
      })
    },

    _reportTypeEdit (id) {
      if (id) {
        this.$http.get(REPORT.TYPE_ITEM, { params: { id: id } }).then(response => {
          this.dataForm.edit = true
          this.dataForm.visible = true
          replaceObjectValues(this.dataForm.fields, response.data.item)
        })
      }
    },

    _reportTypeDelete (id) {
      if (id) {
        this.data.loading = true
        this.$http.post(REPORT.TYPE_DELETE, { id: id }).then(() => {
        }).finally(() => {
          this.data.loading = false
          this._getReportTypeList()
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .thesaurus-report-type .el-dialog {
    width: 500px;
    top: 12vh;
  }
</style>
